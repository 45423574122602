
















































































import { ValidationObserver } from 'vee-validate'
import ODataMixin from '@/mixins/ODataMixin'
import IQuery from '../models/IQuery'
import IProduct from '../models/IProduct'
import IProductSelectionChanged from '../models/IProductSelectionChanged'
import TemplateSelect from '@/areas/templates/components/TemplateSelect.vue'
import SupportPolicySelect from '@/areas/supportPolicies/components/SupportPolicySelect.vue'
import DateFormatSelect from '@/areas/dateFormats/components/DateFormatSelect.vue'

export default ODataMixin.extend({
  props: {
    value: Object as () => IQuery
  },
  data() {
    return {
      query: null,
      loading: false,
      products: [],
      open: [],
      selected: [],
      selectedClone: [],
      productsString: null,
      nameError: false
    }
  },
  components: {
    TemplateSelect,
    ValidationObserver,
    SupportPolicySelect,
    DateFormatSelect
  },
  computed: {
    getTitle(): string {
      return 'Build a Report'
    },
    canSave(): boolean {
      return this.query.graphTemplateId > 0 && this.query.graphQueryProducts?.length > 0
    },
    canGenerateDocument(): boolean {
      const nameField = this.$refs.ReportName as any
      return this.query.graphTemplateId > 0 && this.selected.length > 0 && nameField && this.query.name && this.query.name.length > 0 && !nameField.hasError()
    }
  },
  methods: {
    parseSelected(key: string) {
      const parts = key.split(':')
      return {
        listingId: +parts[0],
        releaseId: +parts[1]
      }
    },
    onProductSelectionChanged(input) {
      this.selectedClone = input
      if (input.length < this.query.graphQueryProducts.filter(x => x.includeInReport).length) {
        this.products.forEach(product => {
          product.children.forEach(release => {
            const index = input.findIndex(x => {
              const selectedKey = this.parseSelected(x)
              return selectedKey.listingId === release.listingId && selectedKey.releaseId === release.id
            })
            if (index === -1) {
              const graphQueryProduct = this.query.graphQueryProducts.find(x => x.listingId === product.id && x.releaseId === release.id)
              if (graphQueryProduct) {
                this.$emit('select-product', graphQueryProduct, false)
              }
            }
          })
        })
      } else if (input.length > this.query.graphQueryProducts.length) {
        input.forEach(x => {
          this.products.forEach(product => {
            product.children.forEach(release => {
              if (release.id === this.parseSelected(x).releaseId) {
                const graphQueryProduct = this.query.graphQueryProducts.find(y => y.listingId === release.listingId && y.releaseId === release.Id)
                if (!graphQueryProduct) {
                  this.$emit('add-product', {
                    graphQueryId: this.query.id,
                    $_productName: product.name,
                    $_releaseName: release.name,
                    listingId: product.id,
                    releaseId: release.id,
                    includeInReport: true
                  })
                }
              }
            })
          })
        })
      } else {
        input.forEach(x => {
          const parsedKey = this.parseSelected(x)
          this.products.forEach(product => {
            product.children.forEach(release => {
              if (release.id === parsedKey.releaseId && product.id === parsedKey.listingId) {
                const graphQueryProduct = this.query.graphQueryProducts.find(y => y.listingId === product.id && y.releaseId === release.id)
                if (graphQueryProduct) {
                  this.$emit('select-product', graphQueryProduct, true)
                }
              }
            })
          })
        })
      }
    },
    updateSummary(productChangedEvent: IProductSelectionChanged) {
      if (productChangedEvent.selected) {
        // check to add product
        const product = this.products.find(x => x.id === productChangedEvent.product.listingId)
        if (!product) {
          const releases = [...productChangedEvent.product.releases].sort((a, b) => a.startDate < b.startDate ? 1 : a.startDate === b.startDate ? 0 : -1).map(x => {
            return {
              key: `${productChangedEvent.product.listingId}:${x.id}`,
              id: x.id,
              uniqueId: x.uniqueId,
              name: x.name,
              listingId: productChangedEvent.product.listingId
            }
          })
          this.products.push({
            key: productChangedEvent.product.listingId,
            id: productChangedEvent.product.listingId,
            uniqueId: productChangedEvent.product.listingUniqueId,
            name: productChangedEvent.product.listingName,
            children: releases
          })
          this.sortProducts()
        }
        // select release
        this.selectedClone.push(`${productChangedEvent.product.listingId}:${productChangedEvent.release.id}`)
      } else {
        // remove selection
        const index = this.selectedClone.findIndex(x => {
          const parsedKey = this.parseSelected(x)
          return parsedKey.listingId === productChangedEvent.product.listingId && parsedKey.releaseId === productChangedEvent.release.id
        })
        this.selectedClone.splice(index, 1)
      }
      this.selected = this.selectedClone
    },
    sortProducts() {
      this.products.sort((x1, x2) => {
        const nameA = x1.name.toUpperCase() // ignore upper and lowercase
        const nameB = x2.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        // names must be equal
        return 0
      })
    },
    onRemoveItem(item: any, leaf: boolean) {
      if (leaf) {
        const graphProduct = this.value.graphQueryProducts.find(x => x.listingId === item.listingId && x.releaseId === item.id)
        this.$emit('remove-product', graphProduct)
      } else {
        const index = this.products.findIndex(x => x.id === item.id)
        this.products.splice(index, 1)
        this.value.graphQueryProducts.filter(x => x.listingId === item.id).forEach(x => {
          this.$emit('remove-product', x)
        })
      }
    },
    async loadQuery(query: IQuery) {
      this.query = query
      if (query.graphQueryProducts.length > 0) {
        const listingIdFilter = Array.from(new Set(query.graphQueryProducts.map(x => `listingId eq ${x.listingId}`)))
        const filterChunks = listingIdFilter.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / 10)
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
          }
          resultArray[chunkIndex].push(item)
          return resultArray
        }, [])
        const tempSelected = []
        const tempProducts = []
        for await (const filterChunk of filterChunks) {
          const filter = filterChunk.join(' or ')
          const response = await this.get(`products?$expand=releases($select=id, uniqueId, name;$orderBy=startDate desc, name desc)&$filter=${filter}`)

          if (response.count > 0) {
            for (let i = 0; i < response.count; i++) {
              const product = response.value[i] as IProduct
              const releases = [] as any[]

              const graphQueryProducts = query.graphQueryProducts.filter(x => x.listingId === product.listingId)
              product.releases.forEach(x => {
                releases.push({
                  key: `${product.listingId}:${x.id}`,
                  id: x.id,
                  uniqueId: x.uniqueId,
                  name: x.name,
                  listingId: product.listingId
                })

                if (query.$_selectAllReleases) {
                  tempSelected.push(`${product.listingId}:${x.id}`)
                } else {
                  const selected = graphQueryProducts.find(release => release.releaseId === x.id)
                  if (selected?.includeInReport) {
                    tempSelected.push(`${product.listingId}:${x.id}`)
                  }
                }
              })
              tempProducts.push({
                key: product.listingUniqueId,
                id: product.listingId,
                uniqueId: product.listingUniqueId,
                name: product.listingName,
                children: releases
              })
            }
          }
        }
        if (query.$_selectAllReleases) {
          const graphQueryProducts = []
          tempProducts.forEach(product => {
            product.children.forEach(release => {
              graphQueryProducts.push({
                listingId: product.id,
                releaseId: release.id,
                includeInReport: true
              })
            })
          })
          this.query.graphQueryProducts = graphQueryProducts
        }
        this.products = tempProducts
        this.selected = tempSelected
        this.sortProducts()
      }
    },
    onTemplateChanged() {
      this.$emit('template-changed', this.query.graphTemplateId)
    }
  },
  async created() {
    this.$emit('loading', true)
    try {
      await this.loadQuery(this.value)
    } finally {
      this.$emit('loading', false)
    }
  }
})
