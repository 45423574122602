















import Vue from 'vue'
import ProductTable from '../components/ProductTable.vue'

export default Vue.extend({
  props: {
  },
  data() {
    return {
    }
  },
  components: {
    ProductTable
  },
  computed: {
  },
  methods: {
  }
})
