

































import ODataMixin from '@/mixins/ODataMixin'
import ODataResult from '@/models/ODataResult'
import IODataTableHeader from '@/models/IODataTableHeader'
import { PropOptions } from 'vue'
import { DataOptions } from 'vuetify'
import { debounce } from 'lodash-es'
import FilterModel from '@/models/FilterModel'
import Filters from '@/components/Filters.vue'
import IODataQuery from '@/models/IODataQuery'
import { RawLocation } from 'vue-router'

export default ODataMixin.extend({
  components: {
    Filters
  },
  props: {
    load: Boolean,
    url: {
      type: String,
      required: true
    },
    filter: {
      type: String
    },
    headers: {
      type: Array,
      default: () => []
    } as PropOptions<IODataTableHeader[]>,
    extraSearchFields: {
      type: Array,
      default: () => []
    } as PropOptions<IODataTableHeader[]>,
    itemsPerPage: {
      type: Number,
      default: () => 100
    },
    sortBy: {
      type: Array,
      default: () => []
    },
    sortDesc: {
      type: Array,
      default: () => []
    },
    footerProps: {
      type: Object,
      default() {
        return {
          itemsPerPageOptions: [10, 20, 50, 100]
        }
      }
    },
    filters: {
      type: Array,
      default: () => []
    } as PropOptions<FilterModel[]>,
    showFilterCount: {
      type: Boolean,
      default: true
    },
    noSearch: Boolean,
    syncUrl: Boolean,
    useQueryStringId: Boolean,
    handleResultFunc: Function
  },
  watch: {
    options() {
      this.getItems()
    },
    searchText() {
      this.options.page = 1
      this.getItems()
    },
    '$route.query.filter': 'onFilterChange',
    filter() {
      this.onFilterChange()
    },
    load() {
      this.getItems()
    }
  },
  data() {
    return {
      options: {
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      } as DataOptions,
      result: new ODataResult(),
      searchText: this.syncUrl ? (this.$route.query.searchText as string) : null
    }
  },
  computed: {
    items(): any[] {
      const items = this.$clone(this.result.value)
      const headers = this.headers.filter((x) => !!x.format)

      // apply formatting
      for (const header of headers) {
        for (const item of items) {
          const value = item[header.value]
          if (value !== undefined) {
            item[header.value] = this.$formatter.format(value, header.format)
          }
        }
      }
      return items
    },
    activeFilter(): FilterModel {
      return this.filters.find((x) => x.name === this.$route.query.filter)
    },
    filterString(): string {
      // start with base filter string from filter property combined with the selected filter
      let filter = [this.filter, this.activeFilter?.filter]
        .filter((x) => !!x)
        .join(' and ')

      if (this.searchText) {
        const searchWords = this.searchText.split(' ')
        const searchableHeaders = [
          ...this.headers.filter((header) => header.searchable),
          ...this.extraSearchFields
        ]
        const searchFields = searchableHeaders
          .map((header) => header.value.replaceAll('.', '/')) as string[]

        const searchFilter = searchFields
          .map((field) => {
            const fieldFilter = searchWords
              .map(
                (word) =>
                  `contains(tolower(cast(${field}, 'Edm.String')), '${word.toLowerCase()}')`
              )
              .join(' and ')
            return `(${fieldFilter})`
          })
          .join(' or ')

        if (filter) {
          filter += ' and '
        }

        if (searchFilter) {
          filter += `(${searchFilter})`
        }
      }
      return filter
    },
    orderbyString(): string {
      if (!this.options?.sortBy?.length) {
        return null
      }
      const sortBy = this.options.sortBy
      const sortDesc = this.options.sortDesc

      const sortArray: string[] = []
      for (let i = 0; i < sortBy.length; i++) {
        let sortString = `${sortBy[i]} ${sortDesc[i] ? 'desc' : ''}`

        // replace . with / because odata uses / for nested properties
        sortString = sortString.replaceAll('.', '/')
        sortString = sortString.trim()
        sortArray.push(sortString)
      }
      return sortArray.join(',')
    },
    searchPlaceholder(): string {
      const searchableHeaders = [
        ...this.headers.filter((header) => header.searchable),
        ...this.extraSearchFields
      ]
      return `Search: ${searchableHeaders
        .map((x) => x.text)
        .join(', ')}`
    },
    showSearchField(): boolean {
      return (
        !this.noSearch &&
        !(this.useQueryStringId && this.$route.query.id) &&
        (this.headers.some((x) => x.searchable) ||
         this.extraSearchFields.length > 0)
      )
    }
  },
  methods: {
    getItems: debounce(async function (this: any) {
      if (this.load) {
        const $top = this.options.itemsPerPage
        const $skip = (this.options.page - 1) * $top || 0
        const $filter = this.filterString
        const $orderby = this.orderbyString

        const query = {
          $top,
          $skip,
          $filter,
          $orderby
        }

        // if an id was provided in query string; filter it out
        const id = this.$route.query.id
        if (this.useQueryStringId) {
          if (id) {
            query.$filter = `id eq ${id}`
          }
        }

        this.updateUrl()

        const result = await this.get(this.url, query)
        if (this.handleResultFunc) {
          await this.handleResultFunc(result)
        }
        this.result = result

        if (id && this.useQueryStringId) {
          const item = this.result.value[0]
          this.$emit('update:expanded', [item])
        }
      }
    }, 200),
    async getFilterCounts() {
      const filters = this.filters
      const promises = filters.map((namedFilter) => {
        const $filter = [this.filter, namedFilter.filter]
          .filter((x) => !!x)
          .join(' and ')
        const query: IODataQuery = {
          $filter,
          $top: 0
        }
        return this.get(this.url, query)
      })
      const results = await Promise.all(promises)
      for (let i = 0; i < results.length; i++) {
        filters[i].count = results[i].count
      }
    },
    onFilterChange() {
      this.result.value = []
      this.options.page = 1
      this.getItems()
    },
    /** Updates the URL with paging data. */
    updateUrl() {
      if (!this.syncUrl) {
        return
      }

      const route = this.$route
      const newRoute: RawLocation = {
        name: route.name,
        params: route.params,
        query: { ...route.query } || {},
        hash: route.hash,
        path: route.path
      }

      const options = this.options
      newRoute.query.searchText = this.searchText || undefined
      newRoute.query.page = options.page.toString() || undefined
      newRoute.query.itemsPerPage = options.itemsPerPage.toString() || undefined
      newRoute.query.sortBy = options.sortBy.join(',') || undefined
      newRoute.query.sortDesc = options.sortDesc.join(',') || undefined

      if (JSON.stringify(route.query) !== JSON.stringify(newRoute.query)) {
        this.$router.replace(newRoute)
      }
    }
  },
  created() {
    if (this.showFilterCount) {
      this.getFilterCounts()
    }

    // get paging options from url if sync enabled
    if (!this.syncUrl) {
      return
    }
    const query = this.$route.query
    const options = this.options

    options.page = +query.page || options.page || 1
    options.itemsPerPage = +query.itemsPerPage || options.itemsPerPage || +this.itemsPerPage

    if (query.sortBy) {
      options.sortBy = (query.sortBy as string).split(',')
    }

    if (query.sortDesc !== undefined) {
      options.sortDesc = (query.sortDesc as string)
        .split(',')
        .map((x) => x === 'true')
    }
  }
})
