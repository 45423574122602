






















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ISupportPolicy from '../models/ISupportPolicy'
const { mapGetters } = createNamespacedHelpers('SupportPolicies')

export default Vue.extend({
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: 'Lifecycle Policy'
    }
  },
  data() {
    return {
      url: ''
    }
  },
  computed: {
    ...mapGetters(['supportPolicies', 'getSupportPolicyById']),
    tooltip(): string {
      const policies = this.supportPolicies as ISupportPolicy[]
      const selectedPolicy = policies.find((policy) => policy.id === this.value)
      return selectedPolicy?.name
    }
  },
  methods: {
    onSupportPolicyChanged(id) {
      const supportPolicy = this.getSupportPolicyById(id) as ISupportPolicy
      this.url = supportPolicy.externalReferenceUrl
    }
  },
  created() {
    if (this.value) {
      this.onSupportPolicyChanged(this.value)
    }
  }
})
