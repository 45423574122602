




















import { PropOptions } from 'vue'
import ODataMixin from '@/mixins/ODataMixin'
import FilterModel from '@/models/FilterModel'

export default ODataMixin.extend({
  props: {
    filters: {
      type: Array,
      default: () => [],
      required: true
    } as PropOptions<FilterModel[]>,
    showFilterCount: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getRoute(filter: FilterModel) {
      const query = { ...this.$route.query }
      query.filter = filter.name

      delete query.page

      return { name: this.$route.name, query }
    },
    isActive(filter: FilterModel) {
      return filter.name === this.$route.query.filter
    }
  }
})
