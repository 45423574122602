var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Panel',{attrs:{"title":_vm.getTitle,"skeleton-loader":"list-item@12","no-data":!_vm.value}},[_c('v-divider'),_c('div',{staticClass:"ma-3"},[_c('b',[_c('v-chip',[_vm._v("1")]),_c('span',{staticClass:"ml-2"},[_vm._v("Select Products")])],1),_c('v-treeview',{attrs:{"items":_vm.products,"item-key":"key","value":_vm.selected,"open":_vm.open,"selectable":"","selected-color":"gray"},on:{"input":_vm.onProductSelectionChanged},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [(!leaf)?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.onRemoveItem(item, leaf)}}},[_c('v-icon',[_vm._v("$ignore")])],1):_vm._e()]}}])})],1),_c('v-divider'),_c('div',{staticClass:"ma-3"},[_c('b',[_c('v-chip',[_vm._v("2")]),_c('span',{staticClass:"ml-2"},[_vm._v("Select a Language")])],1),_c('TemplateSelect',{staticClass:"mt-2",attrs:{"label":"","outlined":"","dense":"","hide-details":"","rules":"required"},on:{"change":_vm.onTemplateChanged},model:{value:(_vm.query.graphTemplateId),callback:function ($$v) {_vm.$set(_vm.query, "graphTemplateId", $$v)},expression:"query.graphTemplateId"}})],1),_c('div',{staticClass:"ma-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('b',[_c('v-chip',[_vm._v("3")]),_c('span',{staticClass:"ml-2"},[_vm._v("Date Format")])],1)])]}}])},[_c('span',[_vm._v("The date format defaults to the short date format for the selected language. Other options are available in the dropdown list.")])]),_c('DateFormatSelect',{staticClass:"mt-2",attrs:{"label":"","outlined":"","dense":"","hide-details":"","rules":"required"},model:{value:(_vm.query.dateFormat),callback:function ($$v) {_vm.$set(_vm.query, "dateFormat", $$v)},expression:"query.dateFormat"}})],1),_c('div',{staticClass:"ma-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('b',[_c('v-chip',[_vm._v("4")]),_c('span',{staticClass:"ml-2"},[_vm._v("Name your report")])],1),_c('TextField',{ref:"ReportName",attrs:{"label":"Name","rules":"required|query_name","debounce":250,"maxlength":"128","counter":"","autocomplete":"new"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('span',{staticClass:"sr-only"},[_vm._v("(required)")])]},proxy:true}],null,true),model:{value:(_vm.query.name),callback:function ($$v) {_vm.$set(_vm.query, "name", $$v)},expression:"query.name"}})],1)]}}])},[_c('span',[_vm._v("Once generated or saved, you can find this report under "),_c('b',[_vm._v("Saved Reports")])])])],1),_c('v-divider'),_c('div',{staticClass:"ma-3 pb-2"},[_c('v-chip',{},[_c('b',[_vm._v("5")])]),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":!_vm.canGenerateDocument,"color":"primary"},on:{"click":function($event){return _vm.$emit('generate-document')}}},[_vm._v("Generate and Save Report")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }